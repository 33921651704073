@media only screen and (min-width: 1024px) {
    .awssld, .awssld--organic-arrows{
        /* margin-top: 5rem; */
        height: calc(100vh - 8.5rem);
        height:-moz-calc(100vh - 8.5rem);
        height:-webkit-calc(100vh - 8.5rem);
    }
  }

  @media only screen and (max-width: 1023px) {
    .awssld, .awssld--organic-arrows{
        margin-top: 4.5rem;
        /* height: 40vh; */
    }
  }
/* .awssld, .awssld--organic-arrows{
    margin-top: 5rem;
    height: calc(100vh - 12rem);
    height:-moz-calc(100vh - 12rem);
    height:-webkit-calc(100vh - 12rem);;
} */
