html > a {
   color: black;
}
#surveyContainer, #surveyContainer .m-b-xs {
  margin-bottom: 2%;
}
#surveyContainer, #surveyContainer .m-b-md {
  margin-bottom: 10%;
}
#surveyContainer, #surveyContainer .m-t-xs {
  margin-top: 2%;
}
#surveyContainer, #surveyContainer .m-t-sm {
  margin-top: 5%;
}
#surveyContainer {
  outline: 0 !important;
}
#surveyContainer, #surveyContainer body {
  padding: 30px;
  background-color: #37404a;
}
#surveyContainer, #surveyContainer h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 2.2em;
  font-weight: 300;
  color: #85FFC7;
  text-transform: uppercase;
}
#surveyContainer, #surveyContainer p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  color: #FFF;
}
#surveyContainer, #surveyContainer a {
  color: #FFF;
}
#surveyContainer, #surveyContainer a:hover {
  text-decoration: none;
  color: #FFF;
}
#survey-form label, #survey-form .labels {
  display: block;
  margin-bottom: 2%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  color: #FFF;
  letter-spacing: 0.5px;
}
#survey-form input::-webkit-input-placeholder {
  color: transparent !important;
}
#survey-form input::-moz-placeholder {
  color: transparent !important;
}
#survey-form input:-ms-input-placeholder {
  color: transparent !important;
}
#survey-form input:-moz-placeholder {
  color: transparent !important;
}
#survey-form input, #survey-form select {
  display: block;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: 2px solid #333;
}
#survey-form input {
  margin-top: 1.5%;
  padding: 0 0 5px 0;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 2px solid #FFF;
  font-size: 1.1em;
  font-weight: 300;
  color: #85FFC7;
}
#survey-form input:focus {
  border-color: #85FFC7;
}
#survey-form [type="checkbox"], #survey-form [type="radio"] {
  display: inline-block;
  width: auto;
  margin: 0px 10px 0 0;
}
#survey-form [type="checkbox"], #survey-form [type="radio"] {
  display: inline-block;
  margin: 0 10px 0 0 !important;
  position: relative;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 23px;
  width: 23px;
  transition: all 0.15s ease-out 0s;
  background: #37404a;
  color: #FFF;
  cursor: pointer;
  outline: none;
  z-index: 0;
  border: 1px solid #FFF;
}
#survey-form [type="checkbox"]:hover, #survey-form [type="radio"]:hover {
  border-color: #85FFC7;
}
#survey-form [type="checkbox"]:checked::before, #survey-form [type="radio"]:checked::before {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative;
  left: 2px;
  bottom: 3px;
  content: "";
  text-align: center;
  background: url("crossIcon.png") no-repeat center center;
  line-height: 20px;
  font-size: 15px;
  color: #85FFC7;
}
#survey-form [type="checkbox"]:focus, #survey-form [type="radio"]:focus {
  outline: none;
  border-color: #FFF;
}
#survey-form [type="radio"] {
  border-radius: 50%;
}
#survey-form [type="radio"]:after {
  border-radius: 50%;
}
#survey-form [type=number] {
  width: 45px;
}
#survey-form [type=number]::-webkit-inner-spin-button, #survey-form [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
#survey-form select {
  height: 40px;
  padding-left: 5px;
  background-color: #37404a;
  border: 2px solid #FFF;
  color: #85FFC7;
}
#survey-form select option {
  padding: 5px 10px;
  font-weight: 300;
  cursor: pointer;
}
#survey-form select option:hover {
  background-color: #85FFC7;
}
#survey-form textarea {
  resize: none;
  margin-top: 2%;
  padding: 10px 10px 0px 20px;
  width: 100%;
  height: 90px;
  color: #85FFC7;
  background-color: #37404a;
  border: 2px solid #FFF;
}
#survey-form .btn {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 3% 0 0 0;
  height: 45px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 3px solid #85FFC7;
  border-radius: 0;
  font-weight: 500;
  font-size: 1.2em;
  color: #85FFC7;
  text-align: center;
  background: none;
  transition: color 0.25s ease;
}
.btn span{
    z-index: 2;
    position: relative;
}
#survey-form .btn::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #85FFC7;
  transform-origin: left;
  transition: width 0.5s ease;
  z-index: 1;
}
#survey-form .btn:hover {
  color: #37404a;
}
#survey-form .btn:hover:after {
  width: 100%;
}
#survey-form fieldset {
  margin: 5% 0 0 0;
}
.copyright {
  text-align: center;
  font-size: 10px;
  color: #FFF;
}
.copyright i {
  color: #EF5350;
}
@media only screen and (max-width: 1023px) {
    #surveyContainer, #surveyContainer body  {
    padding: 6rem 30px 30px 30px;
  }
}
@media only screen and (max-width: 640px) {
 .m-b-xs.gender {
   display: flex !important;
   flex-direction: column-reverse !important;
 }
}


#surveyContainer .react-datepicker__close-icon::after {
   background-color: #85FFC7;
   color : #37404a;
}

/* width */
#surveyContainer ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#surveyContainer ::-webkit-scrollbar-track {
  background: #37404a;
}

/* Handle */
#surveyContainer ::-webkit-scrollbar-thumb {
  background: #85FFC7;
}

/* Handle on hover */
#surveyContainer ::-webkit-scrollbar-thumb:hover {
  background: #85FFC7;
}

#cancelImage {
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  left: 2px;
  bottom: 3px;
  content: "";
  text-align: center;
  background: url("crossIcon.png") no-repeat center center;
  line-height: 20px;
  font-size: 15px;
  color: #85FFC7;
}

#cancelImage:hover {
  box-shadow: 0 0 11px rgba(62, 226, 125, 0.7); 
  transform: translateY(-2px);
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  left: 2px;
  bottom: 3px;
  content: "";
  text-align: center;
  background: url("crossIcon.png") no-repeat center center;
  line-height: 20px;
  font-size: 15px;
  color: #85FFC7;
}

#survey-form input:not(:focus):invalid {
  /* box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85); */
}

#survey-form input[type="text"]:focus:invalid {
  border: 1px solid red;
  /* outline: none; */
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}

#survey-form input[type="text"].error-input:invalid {
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}

#survey-form input[type="file"]:focus:invalid {
  border: 1px solid red;
  /* outline: none; */
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}

#survey-form input[type="file"].error-input:invalid {
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}

#survey-form input[type="date"]:focus:invalid {
  border: 1px solid red;
  /* outline: none; */
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}

#survey-form input[type="date"].error-input:invalid {
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}

#survey-form select:focus:invalid{ border: 1px solid red; box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);}

#survey-form select.error-select:invalid{border: 1px solid red; box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);}

#survey-form .error {
  border: 1px solid red;
  box-shadow: 4px 4px 20px rgba(200, 0, 0, 0.85);
}